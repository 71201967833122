/* You can add global styles to this file, and also import other style files */
/*@import '@angular/material/core/theming/prebuilt/deeppurple-amber.css';*/
@import "../node_modules/leaflet-contextmenu/dist/leaflet.contextmenu.min.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/flatpickr/dist/flatpickr.css";
@import "../node_modules/angular-notifier/styles.css";

[hidden] {
  display: none !important;
}
body {
  margin: 0 !important; /* This is used to reset any browser-default margins */
  height: 100%; /* This is needed to overcome a Chrome bug. */
  width: 100%; /* As above. */
}
p {
  height: 1.5em;
}
.spacer,
.fill {
  flex: 1 1 auto;
  overflow-y: auto;
}
.header_label {
  font-size: 1.5em;
  font-weight: bold;
}
.active {
  cursor: pointer;
}
mat-dialog-container {
  padding: 0 !important;
}
mat-list {
  flex: 1 1 auto;
  overflow-y: auto;
}
mat-spinner,
mat-spinner > svg {
  height: 1em !important;
  width: 1em !important;
  display: inline-block;
  float: right;
}
.map-icon-label {
  font-size: 10px;
  white-space: nowrap;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}
.detail {
  margin-top: 2%;
  margin-bottom: 2%;
}
.detail-not-exist {
  color: red;
  text-align: center;
}
.my-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  width: 225px !important;
  margin: 13px 19px !important;
  line-height: 1.4 !important;
}
div .color-picker {
  top: 0 !important;
}
.mdl-textfield {
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.mdl-textfield__input {
  /* height: 40px; */
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  width: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.mdl-textfield:hover .mdl-textfield__input {
  width: 200px;
  visibility: visible;
  margin-left: 10px;
}
.mdl-textfield__label {
  display: inline-block;
  margin: 0;
  padding: 0;
  /* height: 40px; */
  width: 30px;
  text-align: center;
  cursor: pointer;
}
.mdl-textfield__label i {
  transform: translateY(10px);
}
.mat-dialog-container {
  margin-top: 40px;
}
.mat-checkbox .mat-checkbox-layout {
  white-space: normal !important;
}
.mat-tab-body-content {
  padding-bottom: 2% !important;
}
a.anchor div div.mat-ripple-element {
  background-color: #fff !important;
}
.cal-month-view .cal-open-day-events {
  background-color: #fff;
}
.mat-datepicker-content-touch {
  margin: 0 !important;
}
.anchor {
  color: #000 !important;
}
.modal-content {
  top: 50px;
  margin-bottom: 50px;
}
@media (max-width: 599px) {
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 16px !important;
  }
}
@media (max-width: 579px) {
  #map-sidebar-tab .mat-tab-label {
    min-width: 160px !important;
  }
}
@media (max-width: 691px) {
  .mat-datetimepicker-calendar {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    display: block !important;
    outline: 0 !important;
    width: 296px !important;
    height: 405px !important;
  }
  .mat-datetimepicker-calendar[mode="auto"]
    .mat-datetimepicker-calendar-header {
    width: auto !important;
  }
}

@media (max-width: 450px) {
  .mat-datetimepicker-calendar {
    width: 196px !important;
    height: 290px !important;
  }
  .mat-datetimepicker-calendar-header-date-time {
    font-size: 20px !important;
    line-height: 34px !important;
  }
  .mat-datetimepicker-calendar-content mat-datetimepicker-clock {
    min-width: 100px;
  }
}
@media (max-width: 480px) {
  #map-sidebar-tab
    .mat-tab-header-pagination-controls-enabled
    .mat-tab-header-pagination {
    display: flex;
  }
  .mat-icon-button {
    margin-left: 4px !important;
  }
  .logout-icon {
    padding-right: 6px !important;
  }
}
@media (max-width: 599px) {
  .customerjob-analysis .mat-tab-label {
    min-width: 65px;
    padding: 0 16px;
  }
}
